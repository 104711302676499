import React from 'react'
import { Container } from '@mui/material'
import { graphql, PageProps } from 'gatsby'

import ContentComponents from 'public/components/molecules/contentComponents/ContentComponents'
import Layout from 'public/components/organisms/global/Layout'
import PageTitleHero from 'public/components/organisms/hero/PageTitleHero'
import Section from '_core/components/atoms/layout/Section'

interface Props {
  data: Queries.BasicPageDetailQuery
  pageContext: PageProps
}

const BasicPageDetail = ({ data, pageContext }: Props) => {
  const page = data.datoCmsBasicPage
  return (
    <Layout
      metadata={{
        seoTags: page.seoMetaTags,
      }}
    >
      <PageTitleHero
        mainHeading={page.title}
        imageLandscape={page.imageLandscape.gatsbyImageData}
        imagePortrait={page.imageLandscape.gatsbyImageData}
      />
      <Section vSpace>
        <Container maxWidth="lg">
          <ContentComponents components={page.contentComponents} />
        </Container>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query BasicPageDetail($originalId: String!) {
    datoCmsBasicPage(originalId: { eq: $originalId }) {
      title
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      imageLandscape: heroBackgroundImage {
        gatsbyImageData(
          width: 1600
          layout: FULL_WIDTH
          imgixParams: { ar: "16:5", fit: "crop", crop: "focalpoint", sat: -100 }
        )
      }
      imagePortrait: heroBackgroundImage {
        gatsbyImageData(
          width: 900
          layout: FULL_WIDTH
          imgixParams: { ar: "3:4", fit: "crop", crop: "focalpoint", sat: -100 }
        )
      }
      contentComponents {
        ... on DatoCmsHeading {
          model {
            apiKey
          }
          originalId
          heading
        }

        ... on DatoCmsText {
          originalId
          model {
            apiKey
          }
          textNode {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`

export default BasicPageDetail
